import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	padding: 1.5rem 0;
	display: flex;
	justify-content: space-between;

	a {
		color: #212121;

	}
`;
